html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

a {
  color: inherit !important;
}

textarea {
  overflow-y: auto !important;
}

/* For Firefox */
/*
input[type='number'] {
  -moz-appearance:textfield;
}
*/
/* Webkit browsers like Safari and Chrome */
/*
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
*/